<template>
  <div class="PersonnelscoreBox item-b" v-loading="showLoad">
    <FormItem
      :formList="formList"
      :btn="btn"
      @search="onSearch"
      @export="onExport"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
      @changeRadio="radioHandle"
    />
    <Table
      ref="table"
      :tableList="tableList"
      :tableData="tableData"
      @currentNum="current"
      @operation="onHandle"
      @reset="onReset"
      :total="total"
      @cellclick="goRecord"
      @cellmouse="oncellmouse"
      :navtor = true
    />
    <!-- 项目部弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="boxTitle">{{ eaitData.projectDeptName }}</div></el-col
        >
        <el-col :span="6">
          <div>
            <el-date-picker
              v-model="value3"
              type="month"
              format="yyyy-MM"
              @change="selectDate(value3)"
              placeholder="选择月"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <!-- <el-date-picker
              class="custom-input"
              v-model="value3"
              type="year"
              value-format="yyyy"
              format="yyyy"
              placeholder="选择年份"
            >
            </el-date-picker> -->
          </div>
        </el-col>

        <el-col :span="2"
          ><div>
            <el-button
              type="primary"
              class="item-primary"
              @click="searchClick(value3)"
              >查询</el-button
            >
          </div></el-col
        >
        <el-col :span="2"
          ><div>
            <el-button class="item-info" @click="logReset">重置</el-button>
          </div></el-col
        >
      </el-row>
      <div class="box">
        <div class="boxOne">
          <div class="boxOne1">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月公厕报警次数统计表</span>
              <!-- <span class="boxOneNum">{{listNum.alarmNum}}次</span> -->
            </div>
            <div v-if="Numberalarms.length == 0" class="Processingtime">
              暂无数据
            </div>
            <Echart :option="ChartData1" v-else style="height: 100%"></Echart>
            
          </div>
          <div class="boxOne2">
            <div class="boxOneTitle">
              <span class="boxOneTest">公厕处理时长统计表</span>
              <!-- <span class="boxOneNum">{{listNum.dealWithNum}}H</span> -->
            </div>
            <div v-if="Processingtime.length == 0" class="Processingtime">
              暂无数据
            </div>
            <Echart :option="ChartData2" v-else style="height: 90%"></Echart>
            
          </div>
        </div>
        <div class="boxTwo">
          <div class="boxTwo1">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月公厕得分</span>
              <!-- <span class="boxOneNum">{{listNum.alarmNum}}H</span> -->
            </div>
            <div v-if="toiletsscore.length == 0" class="Processingtime">
              暂无数据
            </div>
            <Echart :option="ChartData3" v-else></Echart>
          </div>
          <div class="boxTwo2">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月公厕分数排名</span>
              <!-- <span class="boxOneNum">第{{Listcount}}名</span> -->
            </div>
            <div v-if="Scoreranking.length == 0" class="Processingtime">
              暂无数据
            </div>
            <div class="washpaiMing" v-else>
              <div class="paiBox">
                <div
                  class="paiMian"
                  v-for="(item, i) in tongLists"
                  :key="i"
                  @click="gowashroomlist(item.toilet_id, item, value4)"
                >
                  <div v-if="i == 0">
                    <span><img src="../assets/img/paione.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 1">
                    <span><img src="../assets/img/paithree.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 2">
                    <span><img src="../assets/img/paitwo.png" alt="" /></span>
                  </div>
                  <div class="paiNum" v-else>
                    <span> {{ i + 1 }}</span>
                  </div>
                  <div class="paiText">{{ item.toilet_name }}</div>
                  <div class="shu1" v-show="i > 0"></div>
                  <div class="shu2" v-show="i > 2"></div>
                  <!-- <div class="shu3" v-show="i > 3"></div> -->
                </div>
              </div>
            </div>
            <div class="dang"></div>
            <div></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <!-- 公厕弹窗 -->
    <el-dialog
      :visible.sync="gongcedialog"
      width="60%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="boxTitle">{{ washroomtitle }}</div></el-col
        >
        <el-col :span="6"
          ><div>
            <el-date-picker
              class="custom-input"
              v-model="value4"
              value-format="yyyy"
              format="yyyy"
              type="year"
              placeholder="选择年份"
              :picker-options="pickerOptions"
            >
            </el-date-picker></div
        ></el-col>
        <el-col :span="2"
          ><div>
            <el-button
              type="primary"
              class="item-primary"
              @click="searchClick2(value4)"
              >查询</el-button
            >
          </div></el-col
        >
        <el-col :span="2"
          ><div>
            <el-button class="item-info" @click="logReset2">重置</el-button>
          </div></el-col
        >
      </el-row>
      <div class="box">
        <div class="boxOne">
          <div class="boxOne1">
            <div class="boxOneTitle">
              <span class="boxOneTest">氨气报警次数统计表</span><span class="boxOneNum">
                <div class="gongce" >
                  <div>
                    <span class="women"></span>
                    <span>女厕</span>
                  </div>
                  <div>
                    <span class="men"></span>
                    <span>男厕</span>
                  </div>
                </div>
              </span>
            </div>
            <Echart :option="ChartData4" style="height: 100%"></Echart>
          </div>
          <div class="boxOne2">
            <div class="boxOneTitle">
              <span class="boxOneTest">硫化氢报警次数统计表</span
              ><span class="boxOneNum">
                <div class="gongce">
                  <div>
                    <span class="women"></span>
                    <span>女厕</span>
                  </div>
                  <div>
                    <span class="men"></span>
                    <span>男厕</span>
                  </div>
                </div>
              </span>
            </div>
            <Echart :option="ChartData5" style="height: 90%"></Echart>
          </div>
        </div>
        <div class="boxTwo">
          <div class="boxTwo1">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月得分</span>
              <span class="boxOneNum">{{ bingFen.score || 0 }}分</span>
            </div>
            <Echart :option="ChartData6"></Echart>
          </div>
          <div class="boxTwo2">
            <div class="boxOneTitle" >
              <span class="boxOneTest">当月公厕分数排名</span>
              <span class="boxOneNum">第{{ washListcount }}名</span>
            </div>
            <div v-if="washroomList.length == 0" class="Processingtime">暂无数据</div>
            <div class="washpaiMing" v-else>
              <div class="paiBox">
                <div
                  class="paiMian"
                  v-for="(item, i) in washroomList"
                  :key="i"
                  @click="gowashroomlist(item.toiletId, item, value4)"
                >
                  <div v-if="i == 0">
                    <span><img src="../assets/img/paione.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 1">
                    <span><img src="../assets/img/paithree.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 2">
                    <span><img src="../assets/img/paitwo.png" alt="" /></span>
                  </div>
                  <div class="paiNum" v-else>
                    <span> {{ i + 1 }}</span>
                  </div>
                  <div class="paiText">{{ item.toiletName }}</div>
                  <div class="shu1" v-show="i > 0"></div>
                  <div class="shu2" v-show="i > 2"></div>
                  <!-- <div class="shu3" v-show="i > 3"></div> -->
                </div>
              </div>
            </div>
            
            <div class="dang"></div>
            <div></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/request";
// import axiosT from "axios";
import Echart from "./Echart";
export default {
  name: "Personnelscore",
  components: { Echart },
  data() {
    return {
      eaitData: {}, // 当前项目部
      washroom: {}, // 当前公厕
      dialogVisible: false,
      gongcedialog: false,
      value3: "",
      value4: "2021",
      showLoad: true,
      curDay: "",
      formList: [
        {
          props: "projectDeptName",
          el: "el-select",
          label: "",
          placeholder: "请选择项目部名称",
          options: [],
        },
        // {
        //   props: 'dateType',
        //   noClear:true,
        //   el:'el-radio-group',
        //   align:'right',
        //   options:[
        //     {
        //       value:'1',
        //       name:'月度'
        //     },
        //     {
        //       value:'2',
        //       name:'年度'
        //     }
        //   ]
        // }
      ],
      btnObj: {
        search: "查询",
        export: "导出",
        reset: "重置",
      },
      btn: "search,export,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          prop: "projectDeptName",
          label: "项目部名称",
          width: "",
        },
        {
          prop: "alertsCount",
          label: "报警次数",
          width: "",
        },
        {
          prop: "processingHour",
          label: "处理时长(总计)",
          width: "",
        },
        {
          prop: "score",
          label: "总计分数",
          width: "",
        },
        {
          prop: "scoreTimeStr",
          label: "评分月度",
          width: "",
        },
        {
          prop: "scoreType",
          label: "总体评分",
          width: "",
        },
        {
          prop: "op",
          label: "操作",
          width: "150",
          btn: [
            {
              type: "text",
              name: "查看统计",
              prop: "edit",
            },
          ],
        },
      ],
      tableData: [],
      tongLists: [],
      listNum: {},
      Listcount: "",
      ChartData1: null, //折线
      ChartData2: null,
      ChartData3: null,
      ChartData4: null,
      ChartData5: null,
      ChartData6: null,
      page: 1,
      size: 10,
      total: 0,
      search: {
        projectDeptName: "",
        dateType: "1",
      },
      Processingtime: [],
      Numberalarms: [],
      toiletsscore: [],
      Scoreranking: [],
      washroomList: [], // 公厕排名列表
      bingFen: {}, //饼图得分,
      washListcount: "", //排名:
      washroomtitle: "",
      Departmentmonth: "", // 项目部月份
      pickerOptions: {
         disabledDate: this.disabledDate
      },
    };
  },
  mounted() {
    console.log(Date.now())
    const time = new Date();
    this.getData(this.page, this.size, this.search);
    this.curDay = `${time.getFullYear()}-${
      time.getMonth() + 1
    }-${time.getDate()}`;
    // this.getWashRoomChartData();
    this.getData(this.page, this.size, this.search);
    this.show();
  },
  methods: {
    handleClose(done) {
      done();
    },
    disabledDate(time) {
       return time.getTime() > Date.now() - 3600 * 1000 * 12 * 1 * 42   //日期可选 ：最少为六周后，之前的都禁用
    },
    oncellmouse(data) {
      if (data.column.property == "projectDeptName") {
        console.log(data);
      }
    },
    show() {
      axios.get(`/projectDept/findAll`).then((res) => {
        console.log(res);
        this.formList[0].options = res.map((res) => {
          return { value: res.deptName, label: res.deptName };
        });
        // this.formList[0].options=res.list
      });
    },
    goRecord(options) {
      console.log(options);
      if (options.column.property == "projectDeptName") {
        let data = options.row;
        this.eaitData = data;
        this.shows(data.projectDeptId, data.scoreTimeStr);
        this.dialogVisible = true;
      }
    },
    getData(page, size, val) {
      this.showLoad = true;
      axios
        .get(`/projectDeptAssess/page/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          console.log(res.list);
          // this.formList[0].options=  res.list.map(res=>{
          //     return {value:res.projectDeptName,label:res.projectDeptName}
          //   })
          // this.formList[0].options=res.list
          this.tableData = res.list;
          this.total = res.total;
          this.showLoad = false;
        });
    },
    onSearch(val) {
      this.search = val;
      this.getData(this.page, this.size, val);
    },
    selectDate(value3) {
      console.log(value3)
      let date = new Date(value3);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      const time = y + "-" + m;
      this.value3 = time;
      return this.value3;
    },
    searchClick() {
      console.log(this.value3);

      this.shows(this.eaitData.projectDeptId, this.value3);
    },
    searchClick2(value4) {
      console.log(value4);
      console.log(this.washroom);
      this.gowashroomlist(this.washroom.toiletId, "", value4);
    },
    radioHandle(val) {
      this.search = val;
      this.getData(this.page, this.size, val);
      if (val.dateType == 1) {
        this.tableList[5].label = "评分月度";
      } else if (val.dateType == 2) {
        this.tableList[5].label = "评分年度";
      }
    },
    onExport(val) {
      this.$export({
        url: "/projectDeptAssess/export",
        method: "get",
        params: { ...this.search },
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a");
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "项目部绩效考核.xlsx";
        link.click();
        URL.revokeObjectURL(objectUrl); // 释放内存
      });
    },
    onReset(val) {
      console.log(val);
      this.dialogVisible = false;
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, val);
    },
    onHandle(data) {
      console.log(data);
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == "del") {
        // this.del(data.data);
      } else if (data.opVal == "edit") {
        console.log(999999);
        this.edit(data.data);
      }
    },
    gowashroomlist(toiletId, item, val) {
      console.log(item);

      this.gongcedialog = true;
      axios
        .get(`/detailsCount/alarmToiletCount?toiletId=${toiletId}&year=${val}`)
        .then((res) => {
          console.log(res);
          this.washroomList = res.toiletScoreVoList;
          console.log(this.washroomList);
          if (this.washroomList.length != 0) {
            this.bingFen = res.toiletScore;
            let kong = this.washroomList.find((res, index) => {
              if (toiletId == res.toiletId) {
                this.washListcount = index + 1;
                return res;
              }
            });
            this.washroom = kong;
            this.washroomtitle = kong.toiletName;
            if (kong == undefined) {
              this.washListcount = 0;
            }
            // 氨气报警次数统计表
            let chartData4 = this.gongceChartData();
            let xMax = Math.max.apply(
              null,
              res.monthH2S.womanCount.concat(res.monthH2S.manCount)
            );
            let yMax = Math.max.apply(
              null,
              res.monthNH3.womanCount.concat(res.monthNH3.manCount)
            );
            // console.log(xMax,yMax)
            console.log(xMax);
            chartData4.series[0].data = res.monthH2S.manCount;
            chartData4.series[1].data = res.monthH2S.womanCount;
            if(xMax>5) {
                chartData4.yAxis.max = xMax;
            } else {
              chartData4.yAxis.max = 5
            }
            
            this.ChartData4 = chartData4;
            // 硫化氢报警次数统计表
            let chartData5 = this.gongceChartData();
            chartData5.series[0].data = res.monthNH3.manCount;
            chartData5.series[1].data = res.monthNH3.womanCount;
            if (yMax>5) {
              chartData5.yAxis.max = xMax;
            } else {
               chartData5.yAxis.max = 5;

            }
           
            this.ChartData5 = chartData5;
            // 当月得分
            let chartData6 = this.yuanLine();
            chartData6.series[0].data[0].value = res.toiletScore.score
              ? res.toiletScore.score
              : 0;
            chartData6.series[0].data[0].name = "得分";
            chartData6.series[0].data[1].value =
              res.toiletScore.total - res.toiletScore.score;
            chartData6.series[0].data[1].name = "未得";
            this.ChartData6 = chartData6;
          }
        });
    },
    edit(data) {
      console.log(data);
      this.eaitData = data;
      this.shows(data.projectDeptId, data.scoreTimeStr);
      this.value3 = data.scoreTimeStr;
      this.dialogVisible = true;
    },
    logReset() {
      this.value3 = "";
      this.Departmentmonth = "";
      console.log(this.eaitData);
      this.shows(this.eaitData.projectDeptId, this.eaitData.scoreTimeStr);
    },
    logReset2() {
      this.value4 = "";
      this.gowashroomlist(this.washroom.toiletId, "", this.value4);
    },
    current(val) {
      this.page = val;
      this.getData(this.page, this.size, this.search);
    },
    shows(deptId, year) {
      axios
        .get(
          `/detailsCount/projectDeptCount?deptId=${deptId}&queryTime=${year}`
        )
        .then((res) => {
          console.log(res);
          this.Processingtime = res.dealWithCount;
          this.Numberalarms = res.alarmCount;
          this.toiletsscore = res.scoreCount;
          this.Scoreranking = res.projectDeptScoreRankList;
          // 当月公厕报警次数统计
          let x = [];
          let data = [];
          let alarmCount = res.alarmCount;
          alarmCount.forEach((element) => {
            x.push(element.toiletName);
            data.push(element.num);
          });
          let chartData1 = this.getChartData();
          console.log(chartData1);
          chartData1.xAxis.data = x;
          chartData1.series[0].data = data;
          console.log(chartData1);
          this.ChartData1 = chartData1;
          // 公厕处理时长统计
          let chartData2 = this.zhuLine();
          console.log(chartData2);
          let dealX = [];
          let dealdata = [];
          let dealWithCount = res.dealWithCount;
          console.log(dealWithCount);
          dealWithCount.forEach((element) => {
            dealX.push(element.toiletName);
            dealdata.push(element.hour);
          });
          chartData2.xAxis[0].data = dealX;
          chartData2.series[0].data = dealdata;
          this.ChartData2 = chartData2;
          // 当月公厕得分
          let chartData3 = this.yuanLine();
          let scoreCount = res.scoreCount;
          console.log(scoreCount);
          for (let y = 0; y < scoreCount.length; y++) {
            chartData3.series[0].data[y].value = scoreCount[y].score;
            chartData3.series[0].data[y].name = scoreCount[y].toilet_name;
          }
          this.ChartData3 = chartData3;
          // for(let i=0;i <= res.scoreCount.length;i++){
          //   chartData3.series[0].data[i].value = res.scoreCount[i].score
          //   chartData3.series[0].data[i].name = res.scoreCount[i].toilet_name
          // }

          // 公厕分数排名
          this.tongLists = res.projectDeptScoreRankList;
          console.log(this.tongLists);

          // scoreCount.forEach(element => {
          //     dealX.push(element.toiletName)
          //     dealdata.push(element.hour)
          // })
          //   this.listNum=res
          //   this.tongLists=res.projectDeptScoreRankList
          // let kong= this.tongLists.find((res,index)=>{
          //    if(deptId==res.projectDeptId){
          //      this.Listcount=index+1
          //      return res
          //    }
          //   })
          //   console.log(kong);
          //   if(kong==undefined){
          //     this.Listcount=0
          //   }
          //   console.log(this.Listcount);
          //   let chartData1 = this.getChartData();
          //   let chartData2 = this.zhuLine();
          //   let chartData3 = this.yuanLine();
          //    let xMax = Math.max.apply(null,res.alarmCount);
          //    let yMax = Math.max.apply(null,res.dealWithCount);
          //    console.log( xMax);
          // //  let yMax = Math.max(...[xnumMax, ynumMax]);
          // // 氨气报警次数统计表
          //  chartData1.series[0].data = res.alarmCount
          //    chartData1.series[1].data = res.monthH2S.womanCount
          //   chartData1.yAxis.max = xMax;
          //   console.log(chartData1.yAxis.max);
          //  this.ChartData1 = chartData1;
          //   //硫化氢报警次数统计表
          // chartData2.series[0].data = res.dealWithCount
          //    chartData2.series[1].data = res.monthNH3.womanCount
          //   chartData2.yAxis.max = yMax;

          //  this.ChartData2 = chartData2
          //    //超载处理时长
          // chartData3.series[0].data[0].value = res.scoreCount.score?res.scoreCount.score:0
          // chartData3.series[0].data[0].name ='得分'
          // chartData3.series[0].data[1].value = res.scoreCount.total-res.scoreCount.score
          // chartData3.series[0].data[1].name ='未得'
          // this.ChartData3 = chartData3
          //   console.log(chartData3);
        });
    },
    // async getWashRoomChartData(time = "2021-5-13", id = 1, type = 1) {
    //   const query = {
    //     params: {
    //       dateTime: time,
    //       toiletId: id,
    //       type,
    //     },
    //   };
    //   let data = await axios.get("/toiletRealDay/findToilet/dayReport", query);
    //   if (data) {
    //     let xLabel = data.manToiletDayReport.map((item) => {
    //       return item.hour;
    //     });
    //     let manValue = data.manToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let womenValue = data.womanToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let selTime = new Date(...time.split("-")).getTime();
    //     let curTime = new Date(...this.curDay.split("-")).getTime();
    //     if (selTime > curTime) {
    //       manValue = [];
    //       womenValue = [];
    //     }
    //     if (selTime === curTime) {
    //       let hour = new Date().getHours();
    //       let index = xLabel.indexOf(hour) + 1;
    //       manValue.splice(index);
    //       womenValue.splice(index);
    //     }
    //     console.log(manValue);
    //     console.log(womenValue);
    //     let xnumMax = Math.max(...manValue);
    //     let ynumMax = Math.max(...womenValue);
    //     let xMax = Math.max(...[xnumMax, ynumMax, this.ammoniaGas]);
    //     let yMax = Math.max(...[xnumMax, ynumMax, this.ydrothion]);
    //     let chartData = this.getChartData();
    //    // chartData.xAxis.data = xLabel;
    //     chartData.series[0].data = manValue;
    //     // chartData.series[1].data = womenValue
    //     if (type === 1) {
    //       let xAxis = chartData;

    //       //xAxis.series[1].markLine.data[0].yAxis=this.ammoniaGas
    //       //  xAxis.series[1].markLine.label.formatter=this.ammoniaGas
    //       // splitNumber: 2,
    //       xAxis.yAxis.max = xMax;
    //       // xAxis.yAxis.interval=0.1
    //       this.ChartData1 = chartData;
    //      this.ChartData2 = this.zhuLine();
    //       this.ChartData3 = this.yuanLine();
    //     } else {
    //       let yAxis = chartData;
    //       //  yAxis.series[1].markLine.data[0].yAxis=this.ydrothion
    //       // yAxis.series[1].markLine.label.formatter=this.ydrothion
    //       // splitNumber: 2,
    //       yAxis.yAxis.max = yMax;
    //       //yAxis.yAxis.interval=0.1
    //       this.ChartData2 = yAxis;
    //       console.log(chartData);
    //     }
    //   }
    // },
    // 饼图
    yuanLine() {
      return {
        legend: {
          orient: "vertical",
          right: 5,
          top: 10,
          formatter: function (name) {
            return name;
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}分({d}%)",
          position: ["0%", "0%"],
        },
        color: [
          " #91CD76",
          "#76CDBC",
          "#FAC958",
          "#5077F3",
          "#EF6765",
          "#F2E87E",
          "#73BFDF",
          "rgb(38, 194, 85)",
          "#F98F39",
          "#C760F7",
          "#38F1C3",
          "#FF6AB0",
        ],
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "公厕得分",
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
            type: "pie",
            radius: [40, 70],
            center: ["30%", "50%"],
            // label: {
            //   normal: {
            //     formatter: "{c}分",
            //     textStyle: {
            //       fontWeight: "normal",
            //       fontSize: 15,
            //     },
            //   },
            // },
            data: [
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
              { value: "", name: "" },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
    },
    //柱状图
    zhuLine() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: 45,
          right: 40,
          // bottom: this.Processingtime.length <5 ?'30':'60',
          top: 15,
        },
        xAxis: [
          {
            name: "",
            nameTextStyle: {
              color: "#333",
              fontSize: 12,
            },
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },

            splitLine: {
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                color: "#0096A8",
              },
            },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              rotate: this.Processingtime.length <5 ?'':'45',
              interval: 0,
              margin: 10,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                // color: "#DEE7FF",
                fontSize: this.Numberalarms.length <5 ?'14px':'',
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
        ],
        yAxis: [
          {
            max: function (value) {
              //取最大值向上取整为最大刻度
              let num;
              if (value.max < 10) {
                console.log(value);
                num = 10 - value.max;
                return Math.ceil(value.max + num);
              } else if (value.max > 99) {
                console.log(value.max);
                return Math.ceil(value.max / 100) * 100;
              } else {
                console.log(Math.ceil(value.max / 10) * 10);
                return Math.ceil(value.max / 10) * 10;
              }
            },
            type: "value",

            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                // color: "#0096A8",
              },
            },
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              rotate: 0,
              margin: 1,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: "#FF6935",
              },
            },
            label: {
              show: true,
              position: "inside",
              color: "#fff",
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            data: [20, 10, 101, 5, 8, 6, 5, 7, 6, 5, 4, 8],
          },
        ],
      };
      // this.$http
      //   .post(
      //     "main/listCarOut",
      //     this.$qs.stringify({
      //       warningtime: this.fromTimeN,
      //       depart: this.fromDepartN,
      //       cartype: this.cartypeTow,
      //     })
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     //data = res;
      //     option.series[0].data = res.data; //已处理

      //   });
    },
    // 折线图
    getChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 45,
          right: 35,
          // bottom: this.Numberalarms<5 ?'30':'60',
          top: 15,
          
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 100,
        //     end: 10,
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 0,
        //     end: 15,
        //   },
        // ],
        xAxis: {
          name: "",
          nameTextStyle: {
            color: "#333",
            fontSize: 12,
          },
          type: "category",
          boundaryGap: true,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: this.Numberalarms.length <5 ?'0':'30',
            margin: 10,
            top:25,
            textStyle: {
              // fontWeight:"bolder",
              color: "#000000",
              fontSize: this.Numberalarms.length <5 ?'14px':'',
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
        yAxis: {
          type: "value",
          max: 50,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "报警",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },

            color: "#2275FF",
            data: [],
            itemStyle: { normal: { label: { show: true } } },
          },
          // {
          //     name: '女厕',
          //     type: 'line',
          //     lineStyle: {
          //         color: "#FF8395",
          //         width: 3,
          //     },
          //     color: "#FF8395",
          //     data: [10, 12, 11, 14, 9, 30, 10,20, 32, 11, 34, 90, 20, 20,20, 12, 11, 14, 90, 23, 21,23,46,24].reverse(),
          //     markLine: {
          //         label:{
          //           position:'middle',
          //           formatter:"5",
          //           fontSize:'16'
          //       },
          //         animation: false,
          //         data: [
          //             {yAxis: 5,}
          //         ],
          //         symbol: "none",
          //         silent: true,
          //         lineStyle: {
          //             type:"solid",
          //             width: 3,
          //             color: "#FF5D31"
          //         }
          //     }
          // },
        ],
        id: 1,
      };
    },
    // 折线图
    gongceChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 45,
          right: 35,
          bottom: 25,
          top: 25,
        },
        xAxis: {
          name: "月",
          nameTextStyle: {
            color: "#333",
            fontSize: 12,
          },
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          min:0, // 设置y轴刻度的最小值
          max:5,  // 设置y轴刻度的最大值
          splitNumber:5,  // 设置y轴刻度间隔个数
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "男厕",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [34, 90, 20, 20, 20, 12, 11, 14, 90, 21, 23, 46],
            itemStyle: { normal: { label: { show: true } } },
          },
          {
            name: "女厕",
            type: "line",
            lineStyle: {
              color: "#FF8395",
              width: 3,
            },
            color: "#FF8395",
            data: [
              10,
              12,
              11,
              14,
              9,
              30,
              10,
              20,
              32,
              11,
              34,
              90,
              20,
              20,
              20,
              12,
              11,
              14,
              90,
              23,
              21,
              23,
              46,
              24,
            ].reverse(),
            itemStyle: { normal: { label: { show: true } } },
            // markLine: {
            //     label:{
            //       position:'middle',
            //       formatter:"5",
            //       fontSize:'16'
            //   },
            //     animation: false,
            //     data: [
            //         {yAxis: 5,}
            //     ],
            //     symbol: "none",
            //     silent: true,
            //     lineStyle: {
            //         type:"solid",
            //         width: 3,
            //         color: "#FF5D31"
            //     }
            // }
          },
        ],
        id: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.PersonnelscoreBox {
  width: 100%;
  ::v-deep.el-dialog__header {
    padding: 0;
    background: red;
  }
  ::v-deep .el-dialog__body {
    background: #f2f6fc;
  }
  ::v-deep .el-dialog__footer {
    background: #f2f6fc;
  }
}

.boxTitle {
  font-size: 22px;
  font-weight: bold;
  color: #3b7fff;
}
.Processingtime {
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.box {
  padding-top: 20px;
  height: 530px;
  display: flex;
  flex-direction: column;
  .boxOne {
    display: flex;
    flex: 1;
    .boxOne1 {
      background: #ffffff;
      flex: 1;
      .chart-item {
        // flex: auto;
        width: 100%;
        height: 100%;
      }
    }
    .boxOne2 {
      margin-left: 10px;
      background: #ffffff;
      flex: 1;
    }
  }
  .boxTwo {
    padding-top: 10px;
    display: flex;
    flex: 1;
    .boxTwo1 {
      background: #ffffff;
      flex: 2;
    }
    .boxTwo2 {
      position: relative;
      margin-left: 10px;
      background: #ffffff;
      flex: 2;
    }
    
  }
}
.boxTwo3 {
  background: #ffffff;
  flex: 1;
}

.boxOneTitle {
  padding: 10px 20px 0px;
  .boxOneTest {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .boxOneNum {
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding-right: 20px;
    color: #f94c1d;
    
  }
}
.gongce{
  display: flex;
  div{
    color: #333333;
    font-weight: 500;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

// 排名
.paiMing {
  width: 100%;
  height: 160px;
  display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;
  // overflow: hidden;
  .paiBox {
    margin: 20px 0px 0px 20px;
    // overflow: scroll;
    height: 160px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .paiMian {
      padding: 0px 0px 0px 0px;
      width: 170px;
      height: 50px;
      // width: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      // flex-direction: column;
      .paiNum {
        margin-left: 5px;
        text-align: center;
        width: 22px;
        height: 20px;
        border-radius: 50%;
        background: #3b7fff;
        color: white;
      }
      .paiText {
        padding: 0px 20px 0px;
      }
    }
  }
}
.washpaiMing {
  width: 100%;
  display: flex;
  overflow: hidden;
  .paiBox {
    margin: 30px 0px 0px 20px;
    overflow: scroll;
    height: 160px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .paiMian {
      padding: 0px 0px 0px 0px;
      width: 170px;
      height: 50px;
      cursor: pointer;
      // width: 50px;
      display: flex;
      align-items: center;

      // flex-direction: column;
      .paiNum {
        margin-left: 5px;
        text-align: center;
        width: 22px;
        height: 20px;
        border-radius: 50%;
        background: #3b7fff;
        color: white;
      }
      .paiText {
        padding: 0px 20px 0px;
      }
    }
  }
}
.women,
.men {
  display: inline-block;
  width: 25px;
  height: 5px;
  background: #ff9393;
  margin: 0 5px;
}
.men {
  background: #3577e5;
}

.dang {
  position: absolute;
  bottom: 8px;
  // width: 700px;
  height: 20px;
  background: #ffffff;
}
.shu1,
.shu2,
.shu3 {
  width: 2px;
  height: 140px;
  background: #ebeff5;
  position: absolute;
  top: 25%;
  left: 32%;
}
.shu2 {
  left: 64%;
}
.shu3 {
  left: 70%;
}
</style>
